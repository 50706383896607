import React from "react";

function Team() {
  return (
    <section className="tc-team-style1">
      <div className="container">
        <div className="mb-80 js-splittext-lines">
          <h2 className="fsz-45"> Meet our top-notch experts </h2>
        </div>
        <div className="members">
          <div className="row">
            <div className="col-lg-2">
              <div className="member-colmn">
                <a href="#" className="team-card wow zoomIn">
                  <div className="img">
                    <img
                      src="/home1/assets/img/sunny.png"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <h5 className="title">
                    {" "}
                    Sunny <br /> Sharma{" "}
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="numbers">
          <div className="row gx-5">
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.2s">
                <h3 className="perc"> 95% </h3>
                <p>
                  {" "}
                  Happy Customer and <br /> Repeating{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.4s">
                <h3 className="perc"> 126 </h3>
                <p>
                  {" "}
                  Projects Completed in <br /> 15 Years{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.6s">
                <h3 className="perc"> 25 </h3>
                <p>
                  {" "}
                  Architects and <br /> High Qualified Crafters{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
